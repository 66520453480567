import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { format as formatDate, parseISO as parseISODate } from 'date-fns';
import Image from 'next/image';
import { colors, spacing } from '../../../../utils/styleguide';
import GhostButton from '../../buttons/GhostButton';
import Typography from '../../text/Typography';

const CardWrapper = styled.div`
  display: flex;
  min-width: 227px;
  max-width: 560px;
  width: -webkit-fill-available;
  height: 464px;
  flex-direction: column;
  gap: ${spacing[4]}px;
  margin: 0 ${spacing[2]}px;

  .slick-slide {
    flex: 1;
  }
`;

const MetaDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[1]}px;
`;

const CopyWrapper = styled.div`
  display: flex;
  padding: 0 ${spacing[4]}px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 0;
  align-self: stretch;
  border-left: 1px solid ${colors.grey03};
`;

const TypographyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]}px;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 50%;
  width: 100%;
  overflow: hidden;
`;

const ImageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export type CarouselItem = {
  title?: string;
  publishedAt: string;
  duration: number;
  url: string;
  externalLink?: string;
  image: {
    url?: string;
    alt: string;
    height: number;
    width: number;
  };
  label: string;
};

export default function CarouselCard({ item }: { item: CarouselItem }) {
  if (!item) return null;

  const { title, publishedAt, duration, url, externalLink, image, label } = item;

  return (
    <CardWrapper>
      <ImageWrapper>
        {image.url ? (
          <Image
            src={`https:${image.url}`}
            alt={image.alt}
            width={image.width}
            height={image.height}
            sizes="(max-width: 320px) 100vw, (max-width: 600px) 50vw, 33vw"
            css={ImageStyles}
          />
        ) : (
          <div
            css={{ bgcolor: 'purple.main', position: 'relative', width: '100%', height: '100%' }}
          />
        )}
      </ImageWrapper>

      <CopyWrapper>
        <TypographyWrapper>
          <MetaDataWrapper>
            <Typography variant="overline" color="text.secondary">
              {formatDate(parseISODate(publishedAt), 'MMM dd, yyyy')}
            </Typography>
            <Typography variant="overline" color="text.secondary">
              •
            </Typography>
            <Typography variant="overline" color="text.secondary">
              {duration} min
            </Typography>
          </MetaDataWrapper>

          <Typography maxNumberOfLines={5} variant="h6Bold">
            {title}
          </Typography>
        </TypographyWrapper>

        <GhostButton
          label={label}
          trailingIconName="arrow-right"
          color={colors.purple400}
          hideUnderline
          href={externalLink ?? url}
          target={externalLink ? '_blank' : '_self'}
          css={{ padding: `${spacing[1]}px 0` }}
        />
      </CopyWrapper>
    </CardWrapper>
  );
}
